<template>
<Page>
  <ViewTitle :title="LocationData.LocationData" />
  <h2 class="display-none-heading">{{ LocationData.PersonalData }}</h2>
  <h3 class="mb-2">{{ LocationData.PersonalData }}</h3>

  <div class="container vcs-location-box p-2 mb-3">
    <div class="row" v-if="person.TypeName=='NaturalPerson'">
      <div class="col-md-6">
        <div class="container">
          <div class="row">
            <div class="col-6 vcs-name p-2">{{ LocationData.FirstName }}</div>
            <div class="col-6 vcs-value p-2">{{ person.FirstName }}</div>
          </div>
          <div class="row">
            <div class="col-6 vcs-name p-2">{{ LocationData.LastName }}</div>
            <div class="col-6 vcs-value p-2">{{ person.LastName }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="container">
          <div class="row">
            <div class="col-6 vcs-name p-2">{{ LocationData.DateOfBirth }}</div>
            <div class="col-6 vcs-value p-2">{{ get_formatted_date(person.DateOfBirth,this.$root.config.localization.language_short_date_format) }}</div>
            </div>
          <div class="row">
            <div class="col-6 vcs-name p-2">{{ LocationData.PlaceOfBirth }}</div>
            <div class="col-6 vcs-value p-2">{{ person.PlaceOfBirth }}</div>
            </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="person.TypeName=='LegalPerson'">
      <div class="col-md-6">
        <div class="container">
          <div class="row"><div class="col-6 vcs-name p-2">{{ LocationData.UserName }}</div><div class="col-6 vcs-value p-2">{{ person.Name }}</div></div>
        </div>
      </div>
    </div>    
  </div>
  


  <b-table class="mb-3" :fields="getCurrentFields" :items="getCurrentResidences" responsive="sm" stacked="md" medium caption-top hover >
    <template v-slot:table-caption>
      <h3 class="mb-2">{{ LocationData.CurrentAddresses }}</h3>
    </template>
    <template v-slot:cell(Type)="data">
      <img class="mr-2" :src="data.item.Emblem ? get_asset_image(`emblems/${data.item.Emblem}`) : get_asset_image('emblems/villach.png')" :alt="data.item.Emblem"  />
      <span v-if="data.value == 'PrincipalResidence'">{{ LocationData.PrincipalResidenceShort }}</span>
      <span v-else>{{ LocationData.ResidenceShort }}</span>
    </template>
    <template v-slot:cell(Street)="data">
      {{ data.value }} {{ data.item.StreetNumber }}
    </template>
    <template v-slot:cell(ValidFrom)="data">
      {{ get_formatted_short_date(data.value) }}
    </template>
  </b-table>
  
  <b-table class="mb-3" :fields="getPreviousFields" :items="getPreviousResidences" responsive="sm" stacked="md" medium caption-top hover >
    <template v-slot:table-caption>
      <h3 class="mb-2">{{ LocationData.PreviousAddresses }}</h3>
    </template>
    <template v-slot:cell(Type)="data" class="type">
      <img class="mr-2" :src="data.item.Emblem ? get_asset_image(`emblems/${data.item.Emblem}`): get_asset_image('emblems/villach.png')" :alt="data.item.Emblem"  />
      <span v-if="data.item.Type == 'PrincipalResidence'">{{ LocationData.PrincipalResidenceShort }}</span>
      <span v-else>{{ LocationData.ResidenceShort }}</span>
    </template>
    <template v-slot:cell(Street)="data">
      {{ data.value }} {{ data.item.StreetNumber }}
    </template>
    <template v-slot:cell(ValidFrom)="data">
      {{ get_formatted_short_date(data.value) }}
    </template>
    <template v-slot:cell(ValidTo)="data">
      {{ get_formatted_short_date(data.value) }}
    </template>
  </b-table>

</Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import { mapActions } from 'vuex';
export default {
  mixins: [vcm,vvm, customLabels],
  name: 'LocationData',
  components: { 
    Page, ViewTitle
  },
  data() {
    return {
    };
  },
  created: function () { 
    this.getUser();
  },
  computed: {
    locationData: function(){
      return this.$store.state.setting.locationData;
    },
    person: function() { 
      return this.$store.state.setting.person;
    },
    getCurrentFields: function() {
      return this.$store.getters['setting/getCurrentFields'];
    },
    getPreviousFields: function() {
      return this.$store.getters['setting/getPreviousFields'];
    },
    getCurrentResidences: function() {
      return this.$store.getters['setting/getCurrentResidences'];
    },
    getPreviousResidences: function() {
      return this.$store.getters['setting/getPreviousResidences'];
    }
  },
  mounted: function () {
    this.addTableHeadText();
  },

  methods: {
    ...mapActions({
      getUser : 'setting/getUserAction'
    }),
    addTableHeadText: function() {
      //document.getElementsByClassName("validto")[0].textContent = 'Auswählen';
      //document.getElementsByClassName("validto")[0].style.display = "none";
    }
  },
}

</script>

<style lang="scss">
@import "@/assets/_var.scss";

caption h3, h3 { color: $appFontColor; font-size: 18px; line-height: 1.688rem; font-weight: 700; }

thead { background-color: #e5eaf0; }
.table-hover tbody tr:hover { color: #121212; background-color:#eff5f9; }

thead tr th { font-size: 0.875rem; line-height: 1.188rem; color: #000000; font-weight: 700; }
tbody tr td { font-size: 0.938rem; line-height: 1.25rem; color: #000000; }

th.emblem { width: 3%; }
th.type { width: 6%; }
th.street { width: 23%; }
td.street { word-break: break-word; }
th.apartmentnumber { width: 5%; }
th.staircase { width: 7%; }
th.postalcode { width: 7%; }
th.city { width: 15%; }

th.validfrom { width: 12%; }
th.validto { width: 12%; }


.vcs-name { font-size: 0.875rem; line-height: 1.25rem; color: #000000;  }
.vcs-value { font-size: 0.875rem; line-height: 1.25rem; color: #000000; font-weight: 700; }
.vcs-value a { color: $highlightFontColor; }
.vcs-value a:hover { color: $highlightFontColor; }
.type > div {
  display: flex;
  justify-content: space-around;
}

.vcs-location-box { border: 1px solid #e5e5e5; max-width: 100%; width: 100%; background-color: #ebf0f4; }

.display-none-heading {
  display: none;
}

@media (max-width: 767px) {
.table.b-table.b-table-stacked-md > tbody > tr > [data-label]:before { 
  width: 25%;
  text-align: inherit;
}

.table td, .table th {
  border-top: unset;
}
.table.b-table.b-table-stacked-md>tbody>tr {
  margin-top : 20px;
}

.table.b-table.b-table-stacked-md>tbody>tr>:first-child {
  border-top: 1px solid #dee2e6;
  padding-top: 20px;
}
}




</style>

